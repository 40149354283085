<template>
  <div class="shipowner">
    <div class="facilityBox">
      <!-- 状态栏 -->
      <el-tabs v-model="activeCmpt" @tab-click="resetForm">
        <el-tab-pane
          :label="item.statusName"
          :name="item.status"
          v-for="(item, index) in statusList"
          :key="index"
        ></el-tab-pane>
      </el-tabs>

      <!-- 检索栏 -->
      <el-form ref="queryForm" :model="queryForm" inline>
        <el-form-item label="是否代收人" prop="IsRecipient" label-width="96px">
          <el-select
            v-model="queryForm.IsRecipient"
            placeholder="请选择是否代收人"
            @change="search"
            style="width: 160px"
          >
            <el-option
              v-for="item in RecipientQueryList"
              :key="item.Code"
              :label="item.Name"
              :value="item.Code"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="船东姓名" label-width="96px">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipownerName"
            placeholder="输入船东姓名"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="船东身份证号">
          <el-input
            class="fromInp"
            v-model="queryForm.ShipownerIDCard"
            placeholder="输入船东姓名"
            style="width: 200px"
          ></el-input>
        </el-form-item>
        <el-form-item label="船东手机号">
          <el-input
            class="fromInp"
            v-model="queryForm.TelPhone"
            placeholder="输入船东姓名"
            style="width: 200px"
          ></el-input>
        </el-form-item>

        <el-button type="primary" size="medium" @click="search" icon="el-icon-search"
          >搜索</el-button
        >
        <el-button type="primary" size="medium" icon="el-icon-delete" @click="resetForm"
          >清空</el-button
        >
        <el-button
          type="primary"
          :icon="showSelect ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
          @click="isShowSelect"
          >更多选项</el-button
        >

        <el-collapse-transition>
          <div style="display: flex; flex-wrap: wrap" v-show="showSelect">
            <el-form-item
              label="是否开立电子账户"
              prop="IsOpenBankAccount"
              label-width="126px"
            >
              <el-select
                v-model="queryForm.IsOpenBankAccount"
                placeholder="请选择是否开立电子账户"
                @change="search"
                style="width: 160px"
              >
                <el-option
                  v-for="item in IsOpenBankAccountList"
                  :key="item.Code"
                  :label="item.Name"
                  :value="item.Code"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              :label="statusList.find((it) => it.status == activeCmpt).timeTxt + '：'"
            >
              <el-date-picker
                v-model="queryForm.SearchDate"
                type="daterange"
                range-separator="至"
                start-placeholder="请选择开始时间"
                end-placeholder="请选择结束时间"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>
            </el-form-item>
          </div>
        </el-collapse-transition>
      </el-form>
      <input
        type="file"
        style="display: none"
        @change="handleUploadData($event, false)"
        ref="uploadData"
      />
      <input
        type="file"
        style="display: none"
        @change="handleUploadData($event, true)"
        ref="collectionAgentuploadData"
      />
      <div class="topbtns">
        <el-button
          type="success"
          size="medium"
          @click="toAdd('add', null)"
          icon="el-icon-circle-plus-outline"
          >添加船东
        </el-button>
        <el-button
          icon="el-icon-wallet"
          type="primary"
          size="medium"
          @click="$refs.uploadData.click()"
          >上传船东表格</el-button
        >
        <el-button
          icon="el-icon-wallet"
          type="primary"
          size="medium"
          @click="$refs.collectionAgentuploadData.click()"
          >上传代收人表格</el-button
        >
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="toDownTemplate"
          >下载船东表格模板</el-button
        >
        <el-button
          icon="el-icon-download"
          type="primary"
          size="medium"
          @click="toExport"
          :disabled="!tableData.length"
          >导出船东表格</el-button
        >
        <el-button
          icon="el-icon-collection"
          type="primary"
          size="medium"
          @click="submitShip('some', null)"
          :disabled="!multipleSelection.length"
          v-if="activeCmpt == 0"
          >批量提交审核</el-button
        >
        <el-button
          icon="el-icon-remove-outline"
          type="danger"
          size="medium"
          @click="toDelete('some', null)"
          :disabled="!multipleSelection.length"
          >批量删除</el-button
        >
      </div>

      <!-- 表格区域 -->
      <el-table
        :data="tableData"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        @selection-change="handleSelectionChange"
        v-loading="loading"
        :key="activeCmpt"
      >
        <el-table-column type="selection" align="center" width="55"></el-table-column>
        <el-table-column
          type="index"
          align="center"
          label="序号"
          width="50"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipownerName"
          label="船东姓名"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipownerIDCard"
          label="船东身份证号"
          show-overflow-tooltip
          width="250"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="TelPhone"
          label="船东手机号"
          show-overflow-tooltip
          width="200"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="IsRecipient"
          label="代收人"
          show-overflow-tooltip
          width="200"
        >
          <template slot-scope="scope">
            {{ scope.row.IsRecipient ? "是" : "否" }}
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="BankName"
          label="开户银行"
          show-overflow-tooltip
          width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BankCardNumber"
          label="银行账号"
          show-overflow-tooltip
          width="300"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="IsOpenBankAccount"
          label="电子账户开立状态"
          show-overflow-tooltip
          width="300"
        >
          <template slot-scope="scope">
            <el-tag :type="scope.row.IsOpenBankAccount ? 'success' : 'danger'">
              {{ scope.row.IsOpenBankAccount ? "已开立" : "未开立" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          prop="StatusTime"
          :label="statusList.find((it) => it.status == activeCmpt).timeTxt"
          width="200"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column
          align="center"
          prop="Remark"
          label="驳回原因"
          fixed="right"
          width="250"
          show-overflow-tooltip
          v-if="activeCmpt == '1'"
        ></el-table-column>
        <el-table-column align="center" label="操作" fixed="right" width="300">
          <template slot-scope="scope">
            <el-button
              type="primary"
              v-if="scope.row.Status == 0"
              @click="toAdd('edit', scope.row)"
              >编辑</el-button
            >
            <el-button type="danger" @click="toDelete('one', scope.row)">删除</el-button>
            <el-button
              type="success"
              v-if="scope.row.Status == 0"
              @click="submitShipOne(scope.row)"
              >提交审核</el-button
            >
            <el-button
              type="primary"
              v-if="scope.row.Status == 10 || scope.row.Status == 100"
              @click="detailShip('detail', scope.row.ID)"
              >详情</el-button
            >
            <el-button
              type="primary"
              v-if="scope.row.Status == 1"
              @click="toAdd('edit', scope.row)"
              >重新提交</el-button
            >
            <el-button
              type="primary"
              v-if="scope.row.Status == 100"
              @click="openContractDialog(scope.row)"
              >合同</el-button
            >
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <div style="margin-top: 10px">
        <el-pagination
          background
          class="pagination"
          @current-change="handleCurrentChange"
          :current-page.sync="queryForm.pageIndex"
          :page-size="queryForm.pageSize"
          :page-sizes="[10, 50, 100, 200]"
          @size-change="sizeChange"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增/编辑弹框 -->
    <el-dialog
      class="showAddDialog"
      append-to-body
      :visible.sync="showAddDialog"
      width="45%"
      v-if="showAddDialog"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title">{{
        dialogType == "detail" ? "查看详情" : !currentItem ? "新增船东" : "编辑船东"
      }}</span>
      <div class="body">
        <div class="title">证照信息</div>

        <div class="content" v-if="shipOwnerStatus != 0 && shipOwnerStatus != 1">
          <div class="upBox">
            <el-image
              :src="driverImg.idFace.picUrl"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">身份证(正面)</div>
          </div>
          <div class="upBox">
            <el-image
              :src="driverImg.idBack.picUrl"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">身份证(反面)</div>
          </div>
          <div class="upBox" v-if="driverImg.SeamanPassportUrl.picUrl">
            <el-image
              :src="driverImg.SeamanPassportUrl.picUrl"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">船员证</div>
          </div>
          <div class="upBox" v-if="driverImg.FlightManagementBookUrl.picUrl">
            <el-image
              :src="driverImg.FlightManagementBookUrl.picUrl"
              :preview-src-list="srcList"
              class="img"
            >
            </el-image>
            <div class="upText">船员船务薄</div>
          </div>
        </div>

        <div class="content" v-if="shipOwnerStatus == 0 || shipOwnerStatus == 1">
          <div class="upBox">
            <UploadImg
              ref="idFace"
              :loading="driverImg.idFace.loading"
              :uploaded="driverImg.idFace.uploaded"
              :picUrl="driverImg.idFace.picUrl"
              @update:file="updateFile"
              :fileType="'Ship'"
              @change="uploadIdFace(driverImg.idFace)"
              @deleteImg="deleteImg(driverImg.idFace, '')"
              v-if="dialogType != 'detail'"
            >
            </UploadImg>
            <el-image
              :src="driverImg.idFace.picUrl"
              :preview-src-list="srcList"
              class="img"
              v-else
            >
            </el-image>
            <div class="upText">
              <span class="required">*</span>
              身份证(正面)
              <i
                @click="imgCase(1)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg
              ref="idBack"
              :loading="driverImg.idBack.loading"
              :uploaded="driverImg.idBack.uploaded"
              :picUrl="driverImg.idBack.picUrl"
              @update:file="updateFile"
              :fileType="'Ship'"
              @change="uploadIdBack(driverImg.idBack)"
              @deleteImg="deleteImg(driverImg.idBack)"
              v-if="dialogType != 'detail'"
            >
            </UploadImg>
            <el-image
              :src="driverImg.idBack.picUrl"
              :preview-src-list="srcList"
              class="img"
              v-else
            >
            </el-image>
            <div class="upText">
              <span class="required">*</span>
              身份证(反面)
              <i
                @click="imgCase(2)"
                class="el-icon-question"
                style="cursor: pointer; color: rgb(230, 160, 62)"
              >
                案例
              </i>
            </div>
          </div>
          <div class="upBox">
            <UploadImg1
              ref="SeamanPassportUrl"
              :loading="driverImg.SeamanPassportUrl.loading"
              :uploaded="driverImg.SeamanPassportUrl.uploaded"
              :picUrl="driverImg.SeamanPassportUrl.picUrl"
              @update:file="updateFile"
              @change="uploadCertificate(driverImg.SeamanPassportUrl)"
              @deleteImg="deleteImg(driverImg.SeamanPassportUrl)"
              v-if="dialogType != 'detail'"
            >
            </UploadImg1>
            <el-image
              :src="driverImg.SeamanPassportUrl.picUrl"
              :preview-src-list="srcList"
              class="img"
              v-else
            >
            </el-image>
            <div class="upText">
              <span class="required" v-show="!addForm.IsRecipient">*</span>船员证
            </div>
          </div>
          <div class="upBox">
            <UploadImg1
              ref="FlightManagementBookUrl"
              :loading="driverImg.FlightManagementBookUrl.loading"
              :uploaded="driverImg.FlightManagementBookUrl.uploaded"
              :picUrl="driverImg.FlightManagementBookUrl.picUrl"
              @update:file="updateFile"
              @change="uploadCertificate(driverImg.FlightManagementBookUrl)"
              @deleteImg="deleteImg(driverImg.FlightManagementBookUrl)"
              v-if="dialogType != 'detail'"
            >
            </UploadImg1>
            <el-image
              :src="driverImg.FlightManagementBookUrl.picUrl"
              :preview-src-list="srcList"
              class="img"
              v-else
            >
            </el-image>
            <div class="upText">
              <span class="required" v-show="!addForm.IsRecipient">*</span>船员航务薄
            </div>
          </div>
        </div>

        <div class="title">基本资料</div>
        <div class="content">
          <el-form
            class="addForm"
            :model="addForm"
            :rules="addRule"
            ref="addForm"
            :disabled="dialogType == 'detail'"
          >
            <el-form-item label="船东姓名" prop="ShipownerName">
              <el-input
                v-model="addForm.ShipownerName"
                placeholder="输入船东姓名"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="船东身份证号" prop="ShipownerIDCard">
              <el-input
                v-model="addForm.ShipownerIDCard"
                placeholder="输入船东身份证号"
              ></el-input>
            </el-form-item>
            <el-form-item label="船东手机号" prop="TelPhone">
              <el-input
                v-model="addForm.TelPhone"
                placeholder="输入船东手机号"
              ></el-input>
            </el-form-item>
            <!-- <el-form-item label="身份证有效期" prop="EquipOwner" label-width="120px">
                            <el-input v-model="addForm.EquipOwner" disabled placeholder="输入身份证有效期"></el-input>
                        </el-form-item> -->
            <el-form-item label="开户银行" prop="BankName">
              <el-input
                v-model="addForm.BankName"
                placeholder="输入开户银行"
                maxlength="50"
              ></el-input>
            </el-form-item>
            <el-form-item label="银行账号" prop="BankCardNumber">
              <el-input
                v-model="addForm.BankCardNumber"
                placeholder="输入银行账号"
                maxlength="32"
              ></el-input>
            </el-form-item>
            <el-form-item label="代收人" prop="IsRecipient">
              <el-switch
                v-model="addForm.IsRecipient"
                active-text="是"
                inactive-text="否"
                @change="IsRecipientSwitchChange"
              >
              </el-switch>
            </el-form-item>
          </el-form>
        </div>

        <div class="title">
          关联船舶
          <div style="display: inline-block; color: red; cursor: pointer">
            注明：船东可关联多艘船舶，船东无船可不填
          </div>
        </div>
        <div>
          <el-button type="primary" @click="bindShiptDialog = true" icon="el-icon-truck"
            >关联船舶</el-button
          >
        </div>
        <div class="content">
          <el-table
            :data="bindShipList"
            v-loading="bindshipLoading"
            :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
            max-height="550"
            style="margin: 16px 0"
          >
            <el-table-column
              fixed
              align="center"
              type="index"
              label="序号"
              width="50"
            ></el-table-column>
            <el-table-column align="center" prop="ShipNO" label="船舶编号">
            </el-table-column>
            <el-table-column
              align="center"
              prop="ShipName"
              width="120"
              label="船舶名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="ShipRegNumber"
              label="船舶登记号"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="BindTime"
              label="绑定时间"
            ></el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" v-if="dialogType != 'detail'">
        <el-button size="medium" @click="showAddDialog = !showAddDialog">取消</el-button>
        <el-button type="primary" size="medium" @click="toStage()" v-if="!currentItem"
          >暂存</el-button
        >
        <el-button type="primary" size="medium" @click="addShip()">{{
          currentItem && currentItem.Status == 1 ? "重新提交" : "提交资料"
        }}</el-button>
      </div>
      <div slot="footer" v-else>
        <el-button size="medium" @click="showAddDialog = !showAddDialog">关闭</el-button>
      </div>
    </el-dialog>

    <!-- 查看账户案例 -->
    <el-dialog
      class="addDialog"
      :visible.sync="isCase"
      width="840px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 查看案例 </span>
      <div v-for="(item, index) in caselist" :key="index">
        <img style="width: 800px" :src="item.SampleURL" />
        <div style="width: 100; text-align: center; font-size: 16px; color: #000">
          {{ item.Name }}
        </div>
      </div>
    </el-dialog>
    <!-- 上传数据错误信息 -->
    <el-dialog :visible.sync="uploadErrorDialog" title="错误说明" center append-to-body>
      <div style="height: 60vh; overflow-y: scroll">
        <el-table
          :data="uploadErrorList"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
        >
          <el-table-column
            align="center"
            prop="LineNumber"
            label="错误行"
            width="200"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="Msg"
            label="错误信息"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>
      </div>
    </el-dialog>

    <!-- 合同管理 -->
    <el-dialog class="addDialog" :visible.sync="contractDialog" width="700px">
      <span slot="title" class="dialog-title"> 管理电子合同 </span>
      <el-alert
        style="margin-bottom: 10px"
        type="warning"
        :closable="false"
        title="温馨提示：请仔细管理好您和合作伙伴之间的各类合同；您仅可以管理自己方的合同"
      >
      </el-alert>
      <el-form inline :model="cntSearchForm" ref="cntSearchForm">
        <el-form-item label="合同名称：" prop="contractName" label-width="85px">
          <el-input
            v-model="cntSearchForm.contractName"
            placeholder="请输入企业名称"
            @keyup.enter.native="GetContractInfo()"
          >
          </el-input>
        </el-form-item>
        <el-form-item label-width="0px">
          <el-button
            type="primary"
            size="medium"
            @click="GetContractInfo()"
            icon="el-icon-search"
            >搜索</el-button
          >
          <el-button
            type="primary"
            size="medium"
            @click="(cntSearchForm.contractName = ''), GetContractInfo()"
            icon="el-icon-delete"
            >清空</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="pactlist"
        height="500px"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        v-loading="pactLoading"
      >
        <el-table-column
          align="center"
          type="index"
          prop="UserID"
          label="序号"
          width="50"
        >
        </el-table-column>
        <el-table-column
          align="center"
          prop="ContractName"
          label="文件名"
          show-overflow-tooltip
        >
        </el-table-column>
        <el-table-column align="center" prop="ModifyDate" label="修改时间" width="200">
        </el-table-column>
        <el-table-column align="center" label="操作" width="220" fixed="right">
          <template slot-scope="scope">
            <!-- <el-button style="padding:0;" v-if="scope.row.ContractMediumType == '01'">
              <div class="up" style="width: 56px;height: 32px;font-size: 12px;line-height: 32px;">
                <input style="width: 56px;height: 32px;" class="upBox" type="file" id="inp" ref="inputer"
                  @change="fileUpload($event, 'list', scope.row)"
                  accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/msword,application/xml-dtd" />
                更新
              </div>
            </el-button> -->
            <el-button type="success" size="small" @click="upPact(scope.row)"
              >下载</el-button
            >
            <!-- <el-button type="danger" size="small" @click="delPact(scope.row)"
              v-if="scope.row.ContractMediumType == '01'">删除</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="pactChange"
        :current-page.sync="pactData.page"
        :page-size="pactData.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="pactData.total"
      >
      </el-pagination>
      <!-- <el-alert type="warning" :closable="false">
        <span slot="title">
          <span> 1.请点击“上传”按钮，新增一份合作文件；</span><br />
          <span> 2.已上传文件，可以更新覆盖、下载到本地、或者删除；</span><br />
          <span> 3.推荐高清PDF扫描文件，请务必保持电子文件的清晰完整。</span><br />
        </span>
      </el-alert> -->
      <!-- <span slot="footer" class="dialog-footer">
        <div class="up" style="text-align: center;margin:10px 48%;">
          <i class="el-icon-upload2"></i>
          <input class="upBox" type="file" id="inp" ref="inputer" @change="fileUpload($event, 'btn')" />
          上传合同
        </div>
      </span> -->
    </el-dialog>

    <!-- 关联车辆 -->
    <el-dialog
      class="addDialog"
      :visible.sync="bindShiptDialog"
      width="740px"
      :close-on-click-modal="false"
    >
      <span slot="title" class="dialog-title"> 关联船舶 </span>
      <div style="width: 100%; text-align: center; padding: 0 0 10px 0">
        船舶名称：
        <el-input
          style="width: 300px"
          v-model="bindShipQuery.ShipName"
          maxlength="10"
          placeholder="请输入船舶名称"
        ></el-input>
        <el-button
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-circle-plus-outline"
          @click="addBindShip()"
        >
          添加船舶</el-button
        >
      </div>
      <el-table
        :data="bindShipList"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
        max-height="550"
      >
        <el-table-column
          fixed
          align="center"
          type="index"
          label="序号"
          width="50"
        ></el-table-column>
        <el-table-column align="center" prop="ShipNO" label="船舶编号"> </el-table-column>
        <el-table-column
          align="center"
          prop="ShipName"
          width="120"
          label="船舶名称"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ShipRegNumber"
          label="船舶登记号"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="BindTime"
          label="绑定时间"
        ></el-table-column>
        <el-table-column align="center" label="操作" width="100">
          <template slot-scope="scope">
            <el-button
              @click="delBindShip(scope.row)"
              type="danger"
              size="small"
              title="删除"
              icon="el-icon-delete"
              >删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        class="pagination"
        @current-change="bindShipCurrentChange"
        :current-page.sync="bindshipPagination.page"
        :page-size="bindshipPagination.pagesize"
        layout="total, prev, pager, next, jumper"
        :total="bindshipPagination.total"
      >
      </el-pagination>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="importListDialogVisible"
      width="70%"
      :close-on-click-modal="false"
      :show-close="false"
    >
      <el-divider></el-divider>
      <el-table
        ref="multipleTable"
        v-if="!progressIng"
        :data="importList"
        height="500px"
        @selection-change="tableSelectionChange"
        :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
      >
        <el-table-column
          type="selection"
          align="center"
          width="55"
          fixed="left"
        ></el-table-column>
        <el-table-column align="center" type="index" label="序号" fixed="left" width="50">
        </el-table-column>

        <el-table-column
          v-for="(item, index) in importListColumns"
          :key="index"
          :fixed="item.fixed"
          :align="item.align"
          :prop="item.prop"
          :type="item.type"
          :label="item.label"
          :width="item.width"
        >
          <template slot-scope="scope">
            <span v-if="item.type == 'string'">
              {{ scope.row[item.prop] || "--------" }}</span
            >
            <div v-else>
              <el-image
                lazy
                style="width: 100px; height: 100px"
                :src="scope.row[item.prop]"
                :preview-src-list=" [scope.row[item.prop]]"
               
                fit="cover"
              >
               
              </el-image>
            </div>
          </template>
        </el-table-column>

        <!-- <el-table-column align="center" fixed="right" label="操作" width="100">
          <template slot-scope="scope"> </template>
        </el-table-column> -->
      </el-table>

      <div
        v-if="progressIng"
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          background: white;
          height: calc(50vh - 106px);
        "
      >
        <el-progress type="circle" :percentage="progress"></el-progress>
        <p style="margin-top: 8px; font-weight: 700; font-size: 18px">同步图片中...</p>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button
          type="danger"
          :disabled="selectedImportList.length == 0"
          @click="deleteImportList"
          >删除选中的行</el-button
        >

        <el-button type="primary" :disabled="!saveImportClive" @click="saveImport"
          >保存</el-button
        >
        <el-button @click="closeOpen" :disabled="!saveImportClive">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const importListColumns = [
  {
    fixed: "left",
    align: "center",
    prop: "ShipownerName",
    width: "200",
    label: "姓名",
    type: "string",
  },
  {
    fixed: "left",
    align: "center",
    prop: "TelPhone",
    width: "150",
    label: "手机号",
    type: "string",
  },
  {
    fixed: "left",
    align: "center",
    prop: "ShipownerIDCard",
    width: "200",
    label: "身份证号码",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "ShipName",
    width: "150",
    label: "船舶名称",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "BankName",
    width: "150",
    label: "开户银行",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "BankCardNumber",
    width: "150",
    label: "银行账号",
    type: "string",
  },
  {
    fixed: false,
    align: "center",
    prop: "IDCardFrontUrl",
    width: "150",
    label: "身份证正面链接",
    type: "img",
  },
  {
    fixed: false,
    align: "center",
    prop: "IDCardBackUrl",
    width: "150",
    label: "身份证反面链接",
    type: "img",
  },
  {
    fixed: false,
    align: "center",
    prop: "FlightManagementBookUrl",
    width: "150",
    label: "船员证链接",
    type: "img",
  },
  {
    fixed: false,
    align: "center",
    prop: "SeamanPassportUrl",
    width: "150",
    label: "船员航务簿链接",
    type: "img",
  },
  // {
  //   fixed: "right",
  //   align: "center",
  //   prop: "ErroMsg",
  //   width: "250",
  //   label: "错误原因",
  //   type: "string",
  // },
];
import UploadImg from "@/components/commonCmpt/cropperImage";
import UploadImg1 from "@/components/commonCmpt/uploadImg";
import { GetSampleInfo, upLoadImgByUrl } from "@/api/common/common";
import { uploadIdFace, uploadIdBack } from "@/api/transport/driverManage/add";
import {
  ShipOwnerPage,
  ShipOwnerAdd,
  ShipOwnerEdit,
  ShipOwnerDetail,
  ShipOwnerSubmit,
  ShipOwnerSubmitList,
  StagingSet,
  StagingGet,
  Import,
  ImportList,
  ImportListImgSrc,
  Export,
  Delete,
  DownTemplate,
  RelationBindCheck,
  RelationBindDel,
  RelationBindPage,
} from "@/api/transport/ship/shipOwner";
import { _getUserId } from "@/utils/storage";
import { GetContractInfo } from "@/api/goodsOwner/pactAdmin/index";
export default {
  data() {
    return {
      importListDialogVisible: false,
      isRecipient: false,
      saveImportClive: true,
      progressIng: false,
      progress: 0,
      importList: [],
      selectedImportList: [],
      importIndex: 0,
      importListColumns,
      showSelect: false, // 控制是否显示筛选框
      //分页控件相关参数
      pagination: {
        total: 0, //总条数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
      },
      loading: false, //表格loading
      activeCmpt: "0",
      statusList: [
        {
          statusName: "待提交",
          status: "0",
          timeTxt: "添加时间",
        },
        {
          statusName: "待审核",
          status: "10",
          timeTxt: "提交时间",
        },
        {
          statusName: "已通过",
          status: "100",
          timeTxt: "审核时间",
        },
        {
          statusName: "已驳回",
          status: "1",
          timeTxt: "驳回时间",
        },
      ],
      showAddDialog: false, //弹框
      currentFile: "", //当前正在操作的文件
      dialogType: "", // 弹窗类型
      // 案例
      isCase: false,
      // 案例列表
      caselist: [],
      //筛选条件
      queryForm: {
        TelPhone: "",
        ShipownerName: "",
        ShipownerIDCard: "",
        SearchDate: ["", ""],
        IsRecipient: null,
        IsOpenBankAccount: null,
        pageIndex: 1, //当前所处的页码
        pageSize: 10, //每次请求的数量
      },
      pactLoading: false,
      //角色属性
      RecipientQueryList: [
        {
          Code: null,
          Name: "全部",
        },
        {
          Code: true,
          Name: "是",
        },
        {
          Code: false,
          Name: "否",
        },
      ],
      shipOwnerStatus: 0,
      //电子账户开立状态
      IsOpenBankAccountList: [
        {
          Code: null,
          Name: "全部",
        },
        {
          Code: true,
          Name: "已开立",
        },
        {
          Code: false,
          Name: "未开立",
        },
      ],

      // 表格数据
      tableData: [],
      multipleSelection: [],
      //提交表单
      addForm: {
        TelPhone: "",
        ShipownerName: "",
        ShipownerIDCard: "",
        BankName: "",
        BankCardNumber: "",
        IsRecipient: false,
      },
      // 表单校验
      addRule: {
        TelPhone: [
          { required: true, message: "请输入船东手机号", trigger: "change" },
          {
            required: true,
            pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        ShipownerName: [{ required: true, message: "请输入船东姓名", trigger: "change" }],
        ShipownerIDCard: [
          { required: true, message: "请输入船东身份证号", trigger: "change" },
          {
            pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
            message: "请输入正确的身份证号",
            trigger: "blur",
          },
        ],
      },
      //人员证件照片
      driverImg: {
        idFace: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        idBack: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        SeamanPassportUrl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
        FlightManagementBookUrl: {
          loading: false,
          uploaded: false,
          picUrl: "",
          file: "",
        },
      },
      currentItem: "", // 当前操作数据
      srcList: [],
      uploadErrorDialog: false,
      uploadErrorList: [],
      contractDialog: false,
      // 合同分页参数
      pactData: {
        page: 1, //当前所处的页码
        pagesize: 4, //每次请求的数量
        total: 0, //总条数
      },
      // 合同列表
      pactlist: [],
      // 管理合同form
      cntSearchForm: {
        contractName: "",
      },
      partyBUserID: 0,

      bindShiptDialog: false, //关联船舶弹窗
      bindShipList: [], //关联船舶数据
      bindshipLoading: false,
      // 关联船舶分页参数
      bindshipPagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      bindShipQuery: {
        ShipName: "",
      },
    };
  },
  components: {
    UploadImg,
    UploadImg1,
  },
  created() {
    this.shipPage();
  },
  methods: {
    //tbale选中改变
    tableSelectionChange(e) {
      this.selectedImportList = e;
    },
    deleteImportList() {
      if (this.selectedImportList.length == 0) {
        this.$message.warning("暂未选中行");
        return;
      }
      this.selectedImportList.forEach((row) => {
        var index = this.importList.findIndex((x) => x == row);
        this.importList.splice(index, 1);
      });
    },
    closeOpen() {
      this.importListDialogVisible = false;
      this.progressIng = false;
      this.isRecipient = false;
      this.progress = 0;
      this.selectedImportList = [];
      this.importList = [];
      this.saveImportClive = true;
      this.importIndex = 0;
    },
    saveImport() {
      if (this.importList.length == 0) {
        this.$message.warning("暂无数据");
        return;
      }
      this.$confirm("是否同步照片", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.saveImportClive = false;
          this.saveImportListImgSrc();
        })
        .catch(() => {
          this.saveImportClive = false;
          this.saveImportBase();
        });
    },
    saveImportBase() {
      const loading = this.$loading({
        lock: true,
        text: "保存中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      Import(this.importList.reverse(), this.isRecipient)
        .then((res) => {
          let { data } = res;
          this.$message.success("导入成功");
          this.shipPage();
          setTimeout(() => {
            if (data == null || data == "") {
              return;
            }
            this.$message.error(
              !this.isRecipient
                ? "存在错误船东,正在下载文件"
                : "存在错误代收人，正在下载文件"
            );
            const elink = document.createElement("a");
            elink.style.display = "none";
            elink.href = data;
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          }, 2000);
        })
        .finally(() => {
          this.closeOpen();
          loading.close();
        });
    },
    saveImportListImgSrc() {
      this.progressIng = true;
      for (let index = 0; index < this.importList.length; index++) {
        const element = this.importList[index];
        ImportListImgSrc(element).then((res) => {
          this.importList[index].ErroMsg = res.data.ErroMsg;
          this.importList[index].IDCardFrontUrl = res.data.IDCardFrontUrl;
          this.importList[index].IDCardBackUrl = res.data.IDCardBackUrl;
          this.importList[index].FlightManagementBookUrl =
            res.data.FlightManagementBookUrl;
          this.importList[index].SeamanPassportUrl = res.data.SeamanPassportUrl;
          this.importIndex = this.importIndex + 1;
          this.progress = parseInt((this.importIndex / this.importList.length) * 100);
          if (this.progress == 100) {
            //完成
            this.importIndex = 0;
            this.progressIng = false;
            this.progress = 0;
            this.$message.success("同步照片完成,即将保存船东列表");
            setTimeout(() => {
              this.saveImportBase();
            }, 1500);
          }
        });
      }
    },
    // 隐藏/显示筛选框
    isShowSelect() {
      this.showSelect = !this.showSelect;
    },

    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.queryForm.pageIndex = e;
      this.shipPage();
    },
    //分页数量改变
    sizeChange(e) {
      this.queryForm.pageSize = e;
      this.shipPage();
    },
    // 多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //上传证件
    uploadCertificate(field) {
      field.loading = true;
      var reader = new FileReader();
      reader.readAsDataURL(this.currentFile);
      reader.onload = () => {
        upLoadImgByUrl({ FileBase64: reader.result, FileType: "Ship" })
          .then((res) => {
            field.loading = false;
            field.uploaded = true;
            if (res.data) {
              field.picUrl = res.data;
            }
          })
          .catch(() => {})
          .finally(() => {
            field.loading = true;
            field.uploaded = true;
          });
      };
    },
    //设置当前组件正在操作的文件
    updateFile(file) {
      this.currentFile = file;
    },
    //删除图片
    deleteImg(field) {
      field.uploaded = false;
      field.loading = false;
      field.picUrl = "";
    },

    // 查看案例
    imgCase(SampleType) {
      this.caselist = [];
      GetSampleInfo({ SampleType }).then((res) => {
        this.caselist = res.data;
        this.isCase = true;
      });
    },
    IsRecipientSwitchChange() {
      if (this.addForm.IsRecipient) {
      } else {
      }
    },
    // 导出表格
    toExport() {
      this.queryForm.Status = Number(this.activeCmpt);
      const loading = this.$loading({
        lock: true,
        text: "导出表格中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      Export(
        {
          ...this.queryForm,
          AscriptionUserID: parseInt(_getUserId()) / 5,
          StatusTimeStart:
            this.queryForm.SearchDate &&
            this.queryForm.SearchDate[0] &&
            this.queryForm.SearchDate[0] + " 00:00:00",
          StatusTimeEnd:
            this.queryForm.SearchDate &&
            this.queryForm.SearchDate[1] &&
            this.queryForm.SearchDate[1] + " 23:59:59",
        },
        {
          responseType: "blob",
        }
      ).catch((err) => {
        let { data } = err;
        console.log("err", err);
        let that = this;
        if (err.type == "application/json") {
          let reader = new FileReader();
          reader.readAsText(err);
          reader.onload = function (e) {
            const { msg } = JSON.parse(reader.result);
            that.$message({
              message: `下载失败 ${msg}`,
              type: "error",
            });
          };
          return;
        }

        const blob = new Blob([data]);
        console.log("blob", blob);
        let fileName = err.headers["content-disposition"].split(";")[1].split("=")[1]; //拆解获取文件名，
        if ("download" in document.createElement("a")) {
          // 非IE下载
          const elink = document.createElement("a");
          elink.download = fileName;
          elink.style.display = "none";
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
        } else {
          // IE10+下载
          navigator.msSaveBlob(blob, fileName);
        }
        loading.close();
      });
    },
    // 下载模板
    toDownTemplate() {
      DownTemplate().then((res) => {
        if (res.data) {
          this.$message.success("下载成功");
        }
        window.location.href = res.data;
      });
    },

    // 上传表格
    handleUploadData(e, isRecipient) {
      const loading = this.$loading({
        lock: true,
        text: "上传表格中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let formData = new FormData();
      formData.append("file", e.srcElement.files[0] || e.target.files[0]);
      formData.append("isRecipient", isRecipient);
      this.isRecipient = isRecipient;
      ImportList(formData)
        .then((res) => {
          this.importListDialogVisible = true;
          this.$nextTick(() => {
            this.importList = res.data;
          });
        })
        .finally(() => {
          if (isRecipient) {
            this.$refs.collectionAgentuploadData.value = null;
          } else {
            this.$refs.uploadData.value = null;
          }
          loading.close();
        });
      // Import(formData)
      //   .then((res) => {
      //     let { data } = res;
      //     this.shipPage();
      //     if ((data == null || data == "")) {
      //       return;
      //     }
      //     this.$message.error(!isRecipient?"存在错误船东,正在下载文件":"存在错误代收人，正在下载文件");
      //     // 非IE下载
      //     const elink = document.createElement("a");
      //     elink.style.display = "none";
      //     elink.href = data;
      //     document.body.appendChild(elink);
      //     elink.click();
      //     URL.revokeObjectURL(elink.href); // 释放URL 对象
      //     document.body.removeChild(elink);
      //   })
      //   .finally(() => {
      //     if (isRecipient) {
      //       this.$refs.collectionAgentuploadData.value = null;
      //     } else {
      //       this.$refs.uploadData.value = null;
      //     }
      //     loading.close();
      //   });
    },
    //上传身份证正面
    uploadIdFace(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadIdFace(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.idFaceUrl;
          //赋值表单
          this.$set(this.addForm, "ShipownerName", res.drivers.name);
          this.$set(this.addForm, "ShipownerIDCard", res.drivers.num);
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idFace.dialog();
        });
    },

    //上传身份证反面
    uploadIdBack(field) {
      field.loading = true;
      let formData = new FormData();
      formData.append("file", this.currentFile);
      uploadIdBack(formData)
        .then((res) => {
          field.loading = false;
          field.uploaded = true;
          field.picUrl = res.drivers.idBackUrl;
        })
        .catch(() => {
          this.deleteImg(field);
        })
        .finally(() => {
          this.$refs.idBack.dialog();
        });
    },

    //列表
    shipPage() {
      this.queryForm.Status = Number(this.activeCmpt);
      this.loading = true;
      ShipOwnerPage({
        AscriptionUserID: parseInt(_getUserId()) / 5,
        ...this.queryForm,
        StatusTimeStart:
          this.queryForm.SearchDate &&
          this.queryForm.SearchDate[0] &&
          this.queryForm.SearchDate[0] + " 00:00:00",
        StatusTimeEnd:
          this.queryForm.SearchDate &&
          this.queryForm.SearchDate[1] &&
          this.queryForm.SearchDate[1] + " 23:59:59",
      })
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = Number(res.data.TotalCount);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    //检索
    search() {
      this.queryForm.pageIndex = 1;
      this.shipPage();
    },

    // 下载合同
    upPact(item) {
      window.open(item.ContractUrl, "_blank");
    },

    //清空检索条件
    resetForm() {
      this.queryForm = {
        pageIndex: 1,
        pageSize: 10,
        SearchDate: ["", ""],
      };
      this.shipPage();
      this.multipleSelection = [];
    },

    //新增/编辑
    toAdd(type, item) {
      this.dialogType = "";
      this.currentItem = item;
      if (type == "add") {
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        StagingGet()
          .then((res) => {
            let stageInfo = res.data ? JSON.parse(res.data) : {};
            if (stageInfo && Object.keys(stageInfo).length) {
              this.driverImg.idFace.picUrl = stageInfo.IdCardFrontUrl || "";
              this.driverImg.idBack.picUrl = stageInfo.IdCardBackUrl || "";
              this.driverImg.SeamanPassportUrl.picUrl = stageInfo.SeamanPassportUrl || "";
              this.driverImg.FlightManagementBookUrl.picUrl =
                stageInfo.FlightManagementBookUrl || "";
              const {
                TelPhone,
                ShipownerName,
                ShipownerIDCard,
                BankName,
                BankCardNumber,
                IsRecipient,
              } = stageInfo;
              this.addForm = {
                TelPhone,
                ShipownerName,
                ShipownerIDCard,
                BankName,
                BankCardNumber,
                IsRecipient,
              };
            } else {
              this.addForm = {
                TelPhone: "",
                ShipownerName: "",
                ShipownerIDCard: "",
                BankName: "",
                BankCardNumber: "",
                IsRecipient: false,
              };
              this.driverImg = {
                idFace: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  type: "idFace",
                },
                idBack: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                  type: "idBack",
                },
                SeamanPassportUrl: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                },
                FlightManagementBookUrl: {
                  loading: false,
                  uploaded: false,
                  picUrl: "",
                  file: "",
                },
              };
            }
            this.showAddDialog = true;
          })
          .finally(() => {
            loading.close();
          });
      } else {
        this.detailShip("edit", item.ID);
      }
    },

    //新增/编辑
    addShip() {
      if (!this.driverImg.idFace.picUrl) {
        this.$message.warning("请上传身份证正面");
        return;
      }
      if (!this.driverImg.idBack.picUrl) {
        this.$message.warning("请上传身份证反面");
        return;
      }
      if (!this.driverImg.SeamanPassportUrl.picUrl && !this.addForm.IsRecipient) {
        this.$message.warning("请上传船员证");
        return;
      }
      if (!this.driverImg.FlightManagementBookUrl.picUrl && !this.addForm.IsRecipient) {
        this.$message.warning("请上传船员船务薄");
        return;
      }
      this.$refs["addForm"].validate((valid) => {
        if (valid) {
          this.loading = true;
          let ajaxFun = this.currentItem ? ShipOwnerEdit : ShipOwnerAdd;

          //关联船舶
          let shipNames = [];
          this.bindShipList.forEach((item) => {
            shipNames.push(item.ShipName);
          });

          ajaxFun({
            AscriptionUserID: parseInt(_getUserId()) / 5,
            ...this.addForm,
            IdCardFrontUrl: this.driverImg.idFace.picUrl,
            IdCardBackUrl: this.driverImg.idBack.picUrl,
            flightManagementBookUrl: this.driverImg.FlightManagementBookUrl.picUrl,
            seamanPassportUrl: this.driverImg.SeamanPassportUrl.picUrl,
            ...(this.currentItem ? { id: this.currentItem.ID } : {}),
            ShipNames: shipNames,
          })
            .then((res) => {
              if (!this.currentItem) {
                // 清空缓存
                StagingSet({ json: JSON.stringify({}) }).then((res) => {});
              }
              if (this.currentItem && this.currentItem.Status == 1) {
                ShipOwnerSubmit({
                  id: this.currentItem.ID,
                })
                  .then((res) => {
                    this.$message.success("操作成功");
                    this.showAddDialog = false;
                    this.shipPage();
                  })
                  .finally(() => {
                    this.loading = false;
                  });
              } else {
                this.loading = false;
                this.$message.success("操作成功");
                this.showAddDialog = false;
                this.shipPage();
              }
            })
            .catch(() => {
              this.loading = false;
            });
        }
      });
    },

    submitShipOne(item) {
      this.$confirm("确定提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: "提交审核中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipOwnerSubmit({
          id: item.ID,
        })
          .then((res) => {
            this.$message.success("操作成功");
            this.shipPage();
            this.multipleSelection = [];
            loading.close();
          })
          .catch(() => {
            loading.close();
          });
      });
    },

    //提交审核
    submitShip(type, item) {
      this.$confirm("确定提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        if (type == "one") {
          ids = [item.ID];
        } else {
          this.multipleSelection.map((it) => {
            ids.push(it.ID);
          });
        }
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ShipOwnerSubmitList(ids)
          .then((res) => {
            this.$message.success("操作成功");
            this.shipPage();
            this.multipleSelection = [];
          })
          .finally(() => {
            loading.close();
          });
        // ids.map(async (it, idx) => {
        //   await ShipOwnerSubmit({
        //     id: it,
        //   })
        //     .then((res) => {})
        //     .catch(() => {
        //       loading.close()
        //     })
        //   if (idx == ids.length - 1) {
        //     this.$message.success('操作成功')
        //     this.shipPage()
        //     this.multipleSelection = []
        //     loading.close()
        //   }
        // })
      });
    },

    // 删除
    toDelete(type, item) {
      this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let ids = [];
        if (type == "one") {
          ids = [item.ID];
        } else {
          this.multipleSelection.map((it) => {
            ids.push(it.ID);
          });
        }
        const loading = this.$loading({
          lock: true,
          text: "请求中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
        });
        ids.map(async (it, idx) => {
          await Delete({
            id: it,
          })
            .then((res) => {})
            .catch(() => {
              loading.close();
            });
          if (idx == ids.length - 1) {
            this.$message.success("操作成功");
            this.shipPage();
            this.multipleSelection = [];
            loading.close();
          }
        });
      });
    },
    //详情
    detailShip(type, id) {
      this.dialogType = type;
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      this.srcList = [];
      ShipOwnerDetail({ id })
        .then((res) => {
          this.addForm = JSON.parse(JSON.stringify(res.data));
          this.driverImg.idFace.picUrl = res.data.IDCardFrontUrl;
          this.driverImg.idBack.picUrl = res.data.IDCardBackUrl;
          this.driverImg.FlightManagementBookUrl.picUrl =
            res.data.FlightManagementBookUrl;
          this.driverImg.SeamanPassportUrl.picUrl = res.data.SeamanPassportUrl;
          this.srcList.push(
            res.data.IDCardFrontUrl,
            res.data.IDCardBackUrl,
            res.data.SeamanPassportUrl,
            res.data.FlightManagementBookUrl
          );
          this.shipOwnerStatus = res.data.Status;
          this.showAddDialog = true;
        })
        .finally(() => {
          loading.close();
          this.pageBindShip();
        });
    },
    // 暂存
    toStage() {
      const loading = this.$loading({
        lock: true,
        text: "请求中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      StagingSet({
        json: JSON.stringify({
          ...this.addForm,
          IdCardFrontUrl: this.driverImg.idFace.picUrl,
          IdCardBackUrl: this.driverImg.idBack.picUrl,
          FlightManagementBookUrl: this.driverImg.FlightManagementBookUrl.picUrl,
          SeamanPassportUrl: this.driverImg.SeamanPassportUrl.picUrl,
        }),
      })
        .then((res) => {
          this.$message.success("操作成功");
          this.showAddDialog = false;
        })
        .finally(() => {
          loading.close();
        });
    },

    openContractDialog(item) {
      this.contractDialog = true;
      this.partyBUserID = item.ShipownerUserID;
      this.GetContractInfo();
    },
    // 获取合同列表
    GetContractInfo() {
      let params = {
        pageSize: this.pactData.pagesize,
        pageIndex: this.pactData.page,
        partyBUserID: this.partyBUserID,
        partyUserID: "0", // 只有平台需要传这个值
        contractName: this.cntSearchForm.contractName, // 根据合同名称查询
        isCheak: true,
      };

      GetContractInfo(params)
        .then((res) => {
          this.pactlist = res.ContractInfo;
          this.pactData.total = Number(res.totalCount);
        })
        .finally(() => {});
    },
    // 合同分页
    pactChange(e) {
      this.pactData.page = e;
      this.GetContractInfo();
    },

    addBindShip() {
      if (this.bindShipQuery.ShipName == "") {
        this.$message({
          message: "请输入船舶名称进行添加",
          type: "warning",
        });
        return;
      }

      //车牌校验
      RelationBindCheck({
        ShipownerIDCard: this.addForm.ShipownerIDCard,
        CarNumber: this.bindShipQuery.ShipName,
      })
        .then((res) => {
          //相同船舶校验
          let shipany = false;
          this.bindShipList.forEach((item) => {
            if (item.ShipName == this.bindShipQuery.ShipName) {
              shipany = true;
            }
          });
          if (shipany) {
            this.$message.error("该船舶已存在列表，请勿重复添加。");
            return;
          }

          this.bindShipList.push(res.data);
        })
        .finally(() => {
          this.bindShipQuery.ShipName = "";
        });
    },

    delBindShip(item) {
      this.$confirm("是否删除该船舶?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        RelationBindDel({
          ShipownerIDCard: this.addForm.ShipownerIDCard,
          CarNumber: item.ShipName,
        }).then((res) => {
          this.bindShipList.forEach((data, index) => {
            if (data.ShipName == item.ShipName) {
              this.bindShipList.splice(index, 1);
            }
          });
        });
      });
    },

    bindShipCurrentChange(e) {
      this.pagination.page = e;
    },

    pageBindShip() {
      RelationBindPage({
        ShipownerIDCard: this.addForm.ShipownerIDCard,
      }).then((res) => {
        this.bindShipList = res.data.DataList;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.shipowner {
  .topbtns {
    padding-bottom: 10px;
    margin: 10px 0px;
    border-bottom: 1px solid #eee;
  }
}

.showAddDialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }

  .title {
    color: #2081ff;
    position: relative;
    text-indent: 15px;
    font-size: 14px;
    margin-bottom: 10px;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 14px;
      border-radius: 5px;
      background: #2081ff;
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

    .upBox {
      text-align: center;

      .upText {
        font-size: 14px;
        font-weight: bold;
        margin: 10px 0 0 0;
        color: #333333;
      }
    }

    .el-upload__tip {
      color: #7e7e7e;
    }
  }

  .img {
    width: 278px;
    height: 174px;
    border-radius: 10px;
  }

  .addForm {
    display: flex;
    // flex-direction: column;
    flex-wrap: wrap;
  }

  ::v-deep .el-form-item__content {
    margin-right: 20px !important;

    .el-input__inner,
    .el-date-editor {
      width: 200px;
    }
  }
}
</style>
